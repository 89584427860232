/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
         // JavaScript to be fired on all pages
        
        // Sticky Nav
         $(window).scroll(function() {
          if ($(document).scrollTop() > 130) {
            $('#bbi-header').addClass('sticky');
          } else {
            $('#bbi-header').removeClass('sticky');
          }
          if ($(document).scrollTop() > 220) {
            $('#bbi-header').addClass('shrink');
          } else {
            $('#bbi-header').removeClass('shrink');
          }
        });


        $('[data-toggle="offcanvas"]').click(function () {
          $('.row-offcanvas').toggleClass('active');
        });


         $('.bbi-nav-primary > ul > li.menu-item-has-children').each(function() {
            $(this).addClass('dropdown');
            $(this).children('a').after('<div class="dropdown bbi-dropdown-arrow visible-xs visible-sm" data-toggle="dropdown"></div>');
            $(this).children('.sub-menu').addClass('dropdown-menu');
        });
        

        $(".dropdown-menu").find("input, button").each(function () {
            $(this).click(function (e) {
                e.stopPropagation();
            });
        });

        $('[data-toggle=dropdown]').each(function() {
         this.addEventListener('click', function() {}, false);
        });
         

        $( "#bbi-primary-content table" ).addClass('table table-bordered').wrap( "<div class='table-responsive'></div>" );
        
        $('iframe[src*="youtube.com"],iframe[src*="vimeo.com"]').each(function() {
          $(this).wrapAll('<div class="bbi-video-wrap"><div class="videoWrapper"></div></div>');
        }); 

        $('.bbi-medium-cont').each(function() {
          $(this).wrapAll('<div class="bbi-center-wrap"></div>');
        }); 

        $('.bbi-tabs').tabCollapse();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.